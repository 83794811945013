/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';
import { Listing_text } from '../../constant/Translation/Listing';

// listingDetails
export const accountDetails = createAsyncThunk(
  'account/accountDetails',
  async ({ slug, id, authKey }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/a/${slug}`, {
        params: { id },
      });
      const { data } = response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const accountSlice = createSlice({
  name: 'account',
  initialState: {
    isFetching: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
    account_details: null,
  },
  reducers: {
    clearAccountState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    setAccountDetails: (state, { payload }) => {
      state.account_details = payload;
    },
    clearAccountDetails: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.account_details = null;
      return state;
    },
  },
  extraReducers: {
    //
    [accountDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
        state.account_details = undefined;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.account_details = payload?.account;
      }
    },
    [accountDetails.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [accountDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.account_details = undefined;
    },
  },
});

export const { clearAccountState, clearAccountDetails, setAccountDetails } =
  accountSlice.actions;
export const accountSelector = (state) => state.account;
